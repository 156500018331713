.Header {
  padding: 10px 0px;
  height: 70px;
  border-bottom: 1px solid #dcdcdc;
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  z-index: 999;
  background-color: #ffff;
  transition: all 1s;
}

.Header.dark {
  border-bottom: 1px solid #2a2a2a;
  background-color: #1f1f1f;
}

.status {
  color: #67c5eb;
  font-size: 14px;
  text-decoration: underline;
  padding-right: 20px;
  cursor: pointer;
}

.swap_status {
  /* max-width: 500px; */
}

.swap_status h1 {
  font-size: 22px;
  text-align: center;
  margin: 20px 0px;
}

.swap_status.dark p {
  background-color: #373737;
  color: #fff;
}

.swap_status.dark h1 {
  color: #ffff;
}

.swap_status p {
  width: 100%;
  background-color: #e9e9e9;
  padding: 10px;
  margin: 20px 0px;
}

.Header img {
  width: 50px;
  /* margin-right: 20px; */
}

.main_logo img {
  width: 160px;
  background-color: #6ebe45;
  padding: 4px 8px;
  border-radius: 10px;
}

.metamask_connector {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-items: center;
}

.nav_holder {
  align-items: center;
  padding: 0px 5%;
}

.metamask_connector img {
  width: 25px;
}

.Header.dark button {
  color: #ffff !important;
}

.darkmodeicon.dark {
  color: rgb(255, 255, 255);
  margin-left: 20px;
  cursor: pointer;
}

.darkmodeicon {
  color: #000000;
  margin-left: 20px;
  cursor: pointer;
}

/* .Header img {
    width: 50px;
    margin-right: 20px;
}

.Header div{
    display: flex;
    justify-content: space-between;
} */
.status_input {
  background-color: transparent;
  border: 1px solid #f7991c;
  color: #ffff;
  outline: none;
  padding: 6px;
}

@media (max-width: 480px) {
  .nav_holder {
    padding: 0;
  }

  .main_logo img {
    width: 80px;
  }

  .language_selector {
    display: none;
  }
}

.language_selector {
  transition: all 1s;
  border: none;
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 10px 20px;
  margin-left: 10px;
  text-transform: uppercase;
}

.language_selector option {
  text-align: left;
}

/////////////////////////////////////////

.rainbow {}
