.input {
  /* padding: 15px; */
  background-color: #282d4b;
  outline: none;
  border: none;
  color: #fff;
  font-size: 16px;
  width: 100%;
  transition: all 1s;
}
.input::placeholder {
  /* color: #f49a15; */
}
.input:disabled {
  color: #9f9f9f;
}
.inputHolder {
  padding: 13px;
  display: flex;
  align-items: center;
  transition: all 1s;
}

.inputHolder img {
  width: 35px;
  padding-right: 10px;
}