.Button {
  background-color: #f49a15;
  color: #ffff;
  /* width: 100%; */
  padding: 10px;
  border: none;
  font-size: 18px !important;
  white-space: normal;
  word-wrap: break-word;
  border-radius: 10px;
  /* margin: 15px 0px 0px; */
}
.Button:focus {
  outline: none;
}
.Button:disabled {
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .Button {
    padding: 10px;
    font-weight: 500 !important;
  }
}
