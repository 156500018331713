.is-dark {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.is-light {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}



.dark-modal{
  color: white;
}

.light-modal{
  color: white;
}


@keyframes page-animation {
  0% {
   opacity: 0.7;
  }
  100% {
   opacity: 1;
  }
}
