* {
  font-family: Poppins;
}

/* Chrome, safari */
* {
  scrollbar-color: #acacac #0d213a10;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #0d213a10;
}

*::-webkit-scrollbar-thumb {
  background-color: #acacac;
}

/* Firefox */
.selector {
  scrollbar-width: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  min-width: 350px !important;
  overflow-x: auto;
  padding: 0;
  margin: 0;
  background-image: linear-gradient(#fbfbfb, #fbfbfb, #4CB64860);
}

.m-l-s {
  margin-left: 20px;
}

table {
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  thead {
    border-bottom: 1px solid #dcdcdc;
  }

  th {
    font-size: 18px;
    font-weight: bold;

    i {
      margin-left: 6px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  tbody td {
    font-size: 14px;
    font-weight: 400;
  }

  th,
  td {
    text-align: start;
    margin: 0;
    padding: 0.5rem;
  }
}

.images-table-avatar {
  display: flex;
  align-items: center;
  column-gap: 10px;

  .image {
    border-radius: 50%;
    object-fit: cover;
    min-width: 30px;
    min-height: 30px;
  }
}

.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-rounded {
  border: 0;
  background-color: #f3f3f3;
}

.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-rounded.Mui-selected {
  border: 0;
  background-color: #3a51af;
  color: white;
}

.btn {
  height: 40px;
  padding: 10px 30px 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  column-gap: 10px;
  border-radius: 5px;
  white-space: nowrap;
  background-color: #3a51af;
  border: 1px solid #3a51af;
  color: white;
}

.btn:not(.btn-disable):hover {
  opacity: 0.7;
}

.outline-btn {
  background: transparent;
  color: #3a51af;
  border: 1px solid #3a51af;
}

.btn-disable {
  opacity: 0.7;
  cursor: not-allowed;
}

.selected-btn {
  color: white;
  border: 0;
  background: #217aff;
}

.red-btn {
  color: white;
  border: 0;
  font-weight: bold;
  background: #ed2024;
}

.green-btn {
  color: white;
  border: 0;
  background: #12772d;
}

.action-btn {
  background-color: #3a51af;
  color: white;
  //box-shadow: none;
  border: 0;
}

.transparent-btn {
  //box-shadow: none;
  border: 0;
  background-color: #dcdcdc;
  color: #acacac;
}

.close-icon {
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 10px;
  right: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon:hover {
  background-color: #80808035;
  border-radius: 50%;
}

.page-bottom-section {
  display: flex;
  width: 78vw;
  flex: 1;
  align-self: flex-end;
  align-items: flex-end;
  justify-content: space-between;
  bottom: 0;
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: white;
  flex-wrap: wrap;
  row-gap: 10px;
  font-size: 12px;
  color: #b2b2b2;
  position: absolute;
}

.currency-cell-container {
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.currency-cell-container span:first-child {
  font-size: 17px;
  font-weight: bold;
}

.currency-cell-container span:nth-child(2) {
  color: #b2b2b2;
}

//custom input styles

.input-error {
  color: red;
  margin-bottom: 10px;
  font-size: 12px;
}

.custom-input-wrapper {
  margin-bottom: 15px;

  .input-error {
    margin-top: 10px;
    color: red;
    font-size: 12px;
  }

  .sub-text {
    font-size: 12px;
    color: #acacac;
    margin-top: 10px;
    display: flex;
    align-items: center;
    row-gap: 10px;
    column-gap: 10px;

    .input-error {
      margin-top: 0;
    }
  }
}

.custom-input-wrapper .label {
  margin-bottom: 5px;
  font-size: 16px;
}

.custom-input-container.banner-duration {
  padding: 0;
  width: 400px;

  .drop-down-main,
  .dropdown-container,
  .dropdown-button {
    width: 100%;
  }

  .dropdown-card {
    width: 92%;
  }
}

.custom-input-container {
  border: 1px solid #dcdcdc;
  margin-top: 5px;
  padding: 12px;
  display: inline-flex;

  align-items: center;
  width: 95%;

  .input {
    width: 100%;
    background-color: transparent;
    border: 0;
    color: inherit;
  }

  .input::placeholder {
    color: #dcdcdc;
  }

  .input:focus {
    outline: none;
  }

  .textarea {
    min-height: 100px;
  }
}

.custom-input-file-container {
  border-style: dashed !important;
  padding: 5px !important;
  padding-left: 15px !important;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  word-break: break-word;
}

.drop-down-in-custom-input {
  padding: 10px !important;
  padding-left: 15px !important;
  width: 96%;
}

.empty-container {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
}

.invalid-feedback {
  font-size: 12px;
  color: red;
}

.category-drop-down {
  .custom-input-container {
    padding: 0;

    .dropdown-button {
      padding: 8px;
      width: 370px;
    }

    .dropdown-card {
      width: 370px;
    }
  }
}

.blocking-screen {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #25252590;
  z-index: 1000;
  padding-top: 30px;
  backdrop-filter: blur(12px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
  min-height: 100vh;
  min-width: 90vw;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pagination-fixed {
  position: fixed;
}

.header-wrapper {
  padding-left: 40px;
  padding-right: 40px;

  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.approve-content {
  display: flex;
  gap: 5px;
  color: #20c409;

  .MuiSvgIcon-root {
    color: #20c409 !important;
  }
}

.reject-content {
  display: flex;
  gap: 5px;
  color: #d5200b;

  .MuiSvgIcon-root {
    color: #d5200b !important;
  }
}

.pending-content {
  display: flex;
  gap: 5px;
  color: #c28c3b;

  .MuiSvgIcon-root {
    color: #c28c3b !important;
  }
}

.image.is-type-video {
  min-height: 280px;
  max-height: 280px;
  display: flex;
  align-items: center;
  background-color: #c2c2c2;
}

.spinner {
  position: relative;
  width: 100px;
  height: 10px;
}

.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f5bb99;
  box-shadow: 20px 0 #f5bb99;
  left: 0;
  top: 0;
  animation: ballMoveX 1.2s linear infinite;
}

.spinner:after {
  box-shadow: none;
  transform: translateX(40px) scale(1);
  z-index: 2;
  animation: none;
  animation: trfLoader 1.2s linear infinite;
}

@keyframes trfLoader {
  0%,
  5% {
    transform: translateX(40px) scale(1);
    background: #f5bb99;
  }
  10% {
    transform: translateX(40px) scale(1);
    background: #d69028;
  }
  40% {
    transform: translateX(20px) scale(1.5);
    background: #d69028;
  }
  90%,
  95% {
    transform: translateX(0px) scale(1);
    background: #d69028;
  }
  100% {
    transform: translateX(0px) scale(1);
    background: #f5bb99;
  }
}

@keyframes ballMoveX {
  0%,
  10% {
    transform: translateX(0);
  }
  90%,
  100% {
    transform: translateX(20px);
  }
}

.error-content {
  display: flex !important;
  flex-direction: column !important;
  gap: 20px;
  word-break: break-word;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller.dark div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.label-input {
  display: flex;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #287400;
  font-size: 12px;
  position: absolute;
  border-radius: 4px;
  left: 8px;
  top: -16px;
}

.copy-btn {
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }
}

.text-error.text-base.font-semibold.m-0 {
  word-break: break-word;
}

@media (max-width: 760px) {
  .manual-btn{
    width: 320px !important;
  }

  .description-manual{
    max-width: 100% !important;
  }

  .center-text-manual{
    text-align: center;
  }
}

.text-warning{
  background-color: #FBF9F9;
  padding: 5px;
  border-radius: 5px;
}

.Button{
  font-weight: 500;
}

@media (max-width: 500px) {
  .text-size-re{
font-size: 15px !important;
    overflow: hidden;
  }

  #input_swap{
    font-size: 15px !important;
  }
}

.hide{
  visibility: hidden;
}

.table-container .last-page .last{
  fill: gray !important;
}

.table-container .first-page .start{
  fill: gray !important;
}