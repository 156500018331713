.table-container {
  box-shadow: none !important;
  border: 1px solid #6ebe45;
  padding: 10px;
  border-radius: 12px !important;
  .MuiPaper-root {
    box-shadow: none !important;
  }

  th,
  td {
    border: none !important;
  }
}

.table-container .pagination-wrapper {
  color: #6ebe45 !important;
  border: 1px solid #e3e3e3;
  padding: 5px 30px;
  border-radius: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 14px !important;
  svg {
    height: 20px !important;
    width: 20px !important;
    color: #6ebe45;
    cursor: pointer;
  }

  span {
    font-size: 14px;
  }
}

.table-container {
  .equal-page {
    .start {
      color: #c4c4c4 !important;
      pointer-events: none;
      cursor: none !important;
    }
    .last {
      color: #c4c4c4 !important;
      pointer-events: none;
      cursor: none !important;
    }
  }

  .last-page {
    .last {
      color: #c4c4c4 !important;
      pointer-events: none;
      cursor: none !important;
    }
  }

  .first-page {
    .start {
      color: #c4c4c4 !important;
      pointer-events: none;
      cursor: none !important;
    }
  }
}
