.main-term-card {
  padding: 0.8em;
  color: gray;

  .main-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4CB648;
  }

  .title {
    font-size: 1.2rem;
    margin-top: 10px;
    color: #4CB648;
  }

  .footer {
    font-size: 0.7rem;
    margin-top: 20px;
    color: black;
  }
}