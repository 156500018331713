.history-list-wrapper {
    .MuiTableBody-root {
        .MuiTableCell-root:nth-child(1) {
            font-weight: 600 !important;
            min-width: 250px;
        }
        .MuiTableCell-root:nth-child(3) .MuiTableCell-root:nth-child(5) {
            max-width: 190px;
        }
    }
}