.nav-bar-styles {
  width: 300px;
  background-color: #eaf7ed;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .nav-bar-styles {
    width: 250px;
  }
}
.navBar-layout {
  background-color: white !important;
}

.modal-nav {
  width: 80vw;
}

@media (max-width: 350px) {
  .modal-nav {
    width: 70vw;
  }
  .mobile-drawer {
    padding: 10px !important;
    font-size: 20px !important;
  }
}
