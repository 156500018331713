.trans_holder {
  padding: 60px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transition: all 1s;
}

.trans_holder.dark {
  padding: 80px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  background-color: #1f1f1f;
}

.checkColor {
  transition: all 1s;
}

.tran_main_cont {
  max-width: 75%;
  padding-top: 50px;
}

.back_button p {
  font-size: 16px;
  color: #231f20;
  margin: 0px;
}

.back_button div {
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.back_button img {
  width: 15px;
  position: relative;
  top: -1;
  margin-right: 5px;
}

.back_button.dark p {
  color: #fff;
  margin-left: 10px;
}

.back_button.dark svg {
  fill: #fff;
}

.back_button {
  display: flex;
  align-items: center;
  margin: 0px;
}

@media (max-width: 760px) {
  .back_button {
    display: flex;
    align-items: center;
    margin: 0px;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
  }
}

.send_addressholder {
  width: 100%;
}

.sender_address {
  position: relative;
  border: 1px solid #e9e9e9;
  border-radius: 15px;
  box-shadow: 0px 12px 18px #0000000d;
  background-color: #ffff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  transition: all 1s;
}

.sender_address.dark {
  background-color: #1f1f1f;
  border: 1px solid #ababab;
}

.sender_address.dark p {
  color: #b2b2b2;
}

.sender_logoholder {
  display: flex;
  align-items: center;
  width: 80%;
  font-size: 16px !important;
  word-break: break-all;
}

.sender_logoholder img {
  width: 25px;
  margin-right: 20px;
}

.sender_logoholder p {
  color: #231f20;
  font-size: 13px;
}

.sender_logoholder.dark p {
  color: #ffff !important;
}

.qr_holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 20px;
}

.qr_holder.dark p {
  color: #fff;
  font-size: 14px;
}

.qr_holder p {
  width: 70%;
  font-size: 16px;
  color: #231f20;
}

.full-holder.qr_holder p {

  font-size: 16px;
  color: #231f20;
}

.qr_holder p span {
  font-weight: 700;
}

.qr_border {
  border: 1px solid #707070;
  padding: 8px;
  background-color: #ffff;
}

.memo_holder {
  padding: 20px 0;
}

.memo_holder.dark h1 {
  color: #ffff;
  font-size: 18px;
}

.memo_holder.dark p {
  color: #ffff;
}

.memo_holder.dark .memo {
  background-color: #1f1f1f;
  border: 1px solid #ababab;
}

.memo_holder.dark .memo p {
  color: #ffff;
}

.memo_holder h1 {
  font-size: 24px;
  color: #231f20;
  font-weight: 700;
}

.memo_holder p {
  color: #b2b2b2;
  font-size: 16px;
}

.memo {
  border: 1px solid #e9e9e9;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 12px 18px #0000000d;
  background-color: #ffff;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 15px;
  text-align: center;
  transition: all 1s;
}

.memo p {
  font-size: 16px;
  color: #231f20;
  text-align: center;
  letter-spacing: 5px;
}

.txid_holder {
  margin-top: 5px;
  margin-bottom: 0px;
  transition: all 1s;
}

.txid_holder.dark {
  /* background-color: #242424; */
}

.txid_holder.dark .txid_inputholder {
  background-color: #1f1f1f;
  border: 1px solid #ababab;
  border-radius: 15px;
}

.txid_holder .sender_address {
  margin-top: 10px;
  margin-bottom: 10px;
}

.status_modal.sender_address {
  display: flex !important;
  justify-content: center;
}

.txid_holder.dark h1 {
  color: #ffff;
}

.txid_holder h1 {
  font-size: 16px;
  color: #231f20;
  font-weight: 700;
}

.txid_inputholder {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  box-shadow: 0px 12px 18px #0000000d;
  background-color: #ffff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: all 1s;
}

.txid_inputholder.error {
  border: 1px solid red;
}

.txid_holder p {
  color: #b2b2b2;
  font-size: 16px;
}

.transleft_holder {
  padding: 0 8%;
}

.trans_holder_pth {
  max-width: 600px;
  margin: auto;
  padding: 6% 0 0 0;
}

/* ----------------CSS Loader */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(247, 153, 28, 0.2);
  border-right: 1.1em solid rgba(247, 153, 28, 0.2);
  border-bottom: 1.1em solid rgba(247, 153, 28, 0.2);
  border-left: 1.1em solid #f7991c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 900px) {
  .trans_holder {
    height: 100% !important;
    padding: 0 !important;
  }

  .transleft_holder {
    margin-top: 2rem !important;
  }
}

/* end spinner */
@media (max-width: 480px) {
  .tran_main_cont {
    margin-left: 0%;
    max-width: 100%;
    padding-top: 35px;
  }

  .transleft_holder {
    padding: 0;
  }

  .transright_holder {
    padding: 0;
  }

  .qr_holder {
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }

  .qr_holder p {
    width: 100%;
  }

  .trans_holder {
    max-width: 100%;
  }

  .left_section {
    width: 100% !important;
    padding: 30px !important;
  }

  .qr_section {
    flex-direction: column !important;
  }

  .qr_section p {
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
  }
}

.qr_section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.qr_section p {
  color: #000;
  padding-left: 20px;
}

.qr_section.dark p {
  color: #fff;
}

.left_section {
  background-color: #fbf9f9 !important;
  border-radius: 30px;
  padding: 40px;
  background: #fff;
  transition: all 1s;
}

@media (max-width: 1024px) {
  .left_section {
    border-radius: 30px;
  }
}

.left_section.dark {
  background: #242424;
}

.left_list li {
  color: #000;
  padding: 20px 0;
  font-size: 14px;
}

.left_list.dark li {
  padding: 20px 0;
  color: #fff;
}

.label-hard {
  top: -12px !important;
  left: 12px !important;
}

.copy {
  img {
    width: 22px !important;
    height: 22px !important;
  }
}


img[src="/svgs/towardsArrow.svg"]{
min-width: 15px;
}


.width-wrap{
  display: flex;

  flex-wrap: wrap;
}