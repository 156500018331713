html {
  * {
    color: #000000;
  }
}

.homeLayout {
  background-color: var(--sec-bg);
  padding: 30px;
  text-align: center;
  max-width: 400px;
  margin: 20px auto;
}

.coinRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
}

.locale-para {
  color: #f7991c;
  border: 1px solid #f7991c;
  border-radius: 4px;
  margin: 0px !important;
}

.homeLayout p {
  color: #ffff;
  text-align: left;
  font-size: 20px;
}

.coinRow p {
  margin: 0;
  text-align: right;
}

.addressHolder {
  margin-top: 25px;
}

.addressHolder p {
  margin: 5px 0px;
}

/* ------------------ */

.feature_icons {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 2%;
  align-items: center;
}

.feature_icons img {
  width: 55px;
  margin-bottom: 15px;
}

.feature_icons h2 {
  font-size: 24px;
  color: #231f20;
  line-height: 30px;
}

.feature_icons.dark h2 {
  color: #ffff;
}

.home_holder {
  /* padding-top: 70px; */
  transition: all 1s;
}

.home_container {
  /* display: flex; */
  /* flex-direction: row; */
  position: relative;
  width: 100% !important;
}

@media (max-width: 768px) {
  .home_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
}

.home_left h1 {
  font-weight: 700;
  color: #231f20;
  line-height: 60px;
  font-size: 42px;
  padding-top: 25px;
}

.home_left.dark h1 {
  color: #ffff;
}

.home_left h1 span {
  /* font-weight: 700; */
  color: #f7991c;
}

.home_left p {
  color: #a4a4a4;
  font-size: 16px;
  /* margin-bottom: 65px; */
  line-height: 30px;
}

.home_left .errorText {
  color: red;
}

.home_right h1 {
  font-weight: 700;
  color: #231f20;
  line-height: 60px;
  font-size: 30px;
}

.home_right.dark h1 {
  color: #ffff;
}

.coin_item {
  box-shadow: 0px 16px 32px #0000001A;
  background-color: #f6f6f6;
  width: 50%;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  transition: all 1s;
}

.coin_item.dark {
  background-color: #2a2a2a;
}

.coin_item.dark .coin_icon p {
  color: #ffff;
}

.coin_item.dark .coin_info h4 {
  color: #ffff;
}

.coin_item + .coin_item {
  margin-left: 10px;
}

.coin_item.dark .coin_trans h2 {
  color: #ffff;
}

.coin_icon {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.coin_icon img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.coin_icon p {
  font-weight: 700;
  font-size: 16px;
  color: #231f20;
  margin: 0px;
}

.coin_info h4 {
  color: #231f20;
  font-size: 20px;
  font-weight: 400;
}

.coin_trans {
  margin-top: 40px;
}

.coin_trans-row {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
}

.coin_trans p {
  font-size: 12px;
  color: #b2b2b2;
}

.coin_trans h2 {
  color: #231f20;
  font-size: 24px;
  font-weight: 400;
  overflow-wrap: break-word;
}

.coin_trans span {
  line-height: 40px;
}

.coin_holder {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.home_right {
  width: 40%;
  padding-top: 10%;
  height: 100%;
}

.home_left {
  justify-content: center;
  align-items: center;
  display: flex;
  /* padding-top: 25px; */
  overflow: scroll;
  margin-left: 10px auto;
  margin-right: auto;
  max-width: 1000px;
}

.home_left::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.home_left {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


.home_input_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.swapicon_holder {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  line-height: 35px;
  text-align: center;
  background: #f6f6f6;
  opacity: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 1;
  transform: translate(50%, -50%);
  right: 50%;
  top: 47%;
  transition: all 1s;
  // border: 3px solid #fff;
  img {
    width: 40px;
    height: 40px;
  }
}
.swapicon_holder.disabled {
  cursor: not-allowed;
  filter: brightness(0.8);
}
.swapicon_holder.dark {
  border: 3px solid #212529;
  background: #2a2a2a;
}

.network_holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fee_details {
  text-align: left;
}

.network_holder p {
  color: #a4a4a4;
  font-size: 12px;
  margin: 0;
}

.networktype_holder {
  margin: 5px 0px 5px;
  display: flex;
  flex-wrap: wrap;
}

.address_holder {
  padding: 0px 0px 20px 0px;
}

.meta_addressholder {
  margin-bottom: 15px;
}

.meta_addressholder p {
  color: #a4a4a4;
  font-size: 12px;
  margin-bottom: 10px;
}

.swap_buttonholder p {
  color: #a4a4a4;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: left;
}

.swap_buttonholder p a {
  color: #231f20 !important;
  text-decoration: underline !important;
}

.swap_buttonholder.dark p a {
  color: #f7991c !important;
  text-decoration: underline !important;
}

.swap_buttonholder {
  text-align: center;
}

.manual_button {
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  background-color: transparent;
  padding: 10px 30px;
  font-size: 12px;
  color: #231f20;
  margin-top: 40px;
}

.manual_button.dark {
  color: #FFFF;
}

@media (max-width: 995px) {

  .home_left {
    position: relative;
    height: 100%;
    padding-top: 70px !important;
  }

  .home_holder {
    height: 100% !important;
  }

  .slider-container {
    position: relative;
    padding-top: 0 !important;
  }

}

@media only screen and (max-width: 426px) {
  .home_left {
    padding-top: 0 !important;
  }
}

@media (max-width: 992px) {
  .home_left {
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .home_right {
    padding: 0;
    width: 80%;
    font-weight: 700;
    color: #231f20;
    font-size: 20px;
  }

  .home_right h1 {
    font-weight: 700;
    color: #231f20;
    line-height: 50px;
    font-size: 18px;
  }

  .feature_icons {
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }

  .coin_holder {
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
  }

  .coin_item {
    margin-right: 0;
    width: 70%;
  }

  .coin_item + .coin_item {
    margin-left: 0;
    margin-top: 10px;
  }

  .add_token {
    justify-content: center !important;
  }
}

@media (max-width: 1024px) {
}

@media (max-width: 1280px) {
  .feature_icons h2 {
    font-size: 22px;
  }
}

@media (max-width: 1440px) {
  .home_left h1 br {
    display: none;
  }

  .feature_icons h2 br {
    display: none;
  }
}

@media (max-width: 480px) {
  .home_input_wrapper {
    flex-direction: column;
    text-align: left;
  }

  .meta_addressholder {
    width: 100% !important;
    margin: 0;
  }

  .feature_icons {
    margin-bottom: 30px;
  }

  .home_holder {
    padding-top: 70px;
  }

  .coin_item {
    width: 100%;
  }

  .address_holder {
    padding: 0px 0px 40px 0;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.slider-container {
  font-family: 'Poppins', sans-serif;
  background-color: #f6f6f6;
  overflow: auto;
  width: 25%;
  position: absolute;
  padding-top: 70px;
  /* position: fixed; */
  overflow: scroll;
  right: 0;
  /* padding-bottom: 4rem; */
  /* height: calc(100vh - 70px); */
  /* height: 100%; */
  height: 100vh;
}

.slider-container.dark {
  background-color: #fff;
}

.swiper-wrapper {
  padding-left: 6%;
  /* padding-bottom: 10px; */
}

.swiper-slide {
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid #ababab;
  padding-left: 55px;
  padding-bottom: 10px;
}

.circle {
  height: 40px;
  width: 40px;
  background-color: #f7991c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;
  right: 75px;
  top: 30px;
}

.bdr {
  display: none;
  background: red;
  width: 1px;
  position: relative;
  left: 20px;
}

.slide-content {
  max-width: 315px;
  width: 100%;
  font-size: 16px;
}

@media (max-width: 995px) {
  .swiper-container {
    width: 100%;
  }

  .slider-container {
    width: 100%;
    position: relative;
  }

}

.slider-container::-webkit-scrollbar {
  display: none;
}

.receiving_inputholder {
  border: 1px solid #e9e9e9;
  border-radius: 15px;
  box-shadow: 0px 12px 18px #0000000D;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 15px;
  transition: all 1s;
}

.input_swap {
  transition: all 1s;
}

#input_swap {
  opacity: 1;
}

@media (max-width: 995px) {
  .receiving_inputholder input {
    font-size: 14px !important;
  }
}

.add_token div {
  cursor: pointer;
  margin-top: 20px;
}

.add_token img {
  width: 15px;
  position: relative;
  top: -1;
  margin-right: 5px;
}

.add_token.dark p {
  color: #fff;
  margin-left: 10px;
}

.add_token {
  display: flex;
  align-items: center;
  margin: 0px;
  justify-content: flex-end;
  padding-right: 10px;
}

@media (max-width: 760px) {
  .add_token {
    display: flex;
    align-items: center;
    margin: 0px;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
  }
}

.Success {
  /* max-width: 480px; */
  /* margin: 100px auto; */
  /* text-align: center; */
  padding: 15px;
}

.Success .modalInfo {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  border: 1px solid #4CB648;
  border-radius: 12px;
  padding: 15px 20px;
}

.Success .modalContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  width: 100%;
}


@media (max-width: 600px) {
  .Success .modalContainer {
    flex-direction: column !important;
    gap: 20px;
  }

  .Success .modalContainer .modalContWrapper{
    width: 100% !important;
  }
}

.Success .modalContainer .modalContWrapper {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: flex-start;

}

.Success .modalContainer .modalContWrapper p {
  padding: 0px;
}

.Success .modalContainer .successSwapicon {
  display: flex;
  width: 33%;
}

.Success div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Success div h3 {
  color: #f7991c;
  padding-left: 10px;
  text-align: center;
  margin: 0px;
  font-weight: bold;
}

.Success div div p {
  margin: 0px;
  font-size: 14px;
}

.Success div p {
  text-align: center;
  margin: 0px;
  font-size: 18px;
}

.Success.dark h1 {
  color: #ffff;
}

.Success.dark p {
  color: #a4a4a4;
}

.Success h1 {
  font-size: 40px;
  color: #231f20;
  font-weight: 700;
  margin-top: 40px;
}

.Success p {
  /* color: #231f20; */
  margin-top: 20px;
  text-align: center;
  padding: 15px 0;
  font-size: 14px;
  margin: 0px;
}

.requestid_holder {
  margin-top: 134px;
}

.requestid_holder p {
  color: #a4a4a4;
  font-size: 16px;
}

.requestid_holder h4 {
  font-size: 16px;
  font-weight: 700;
  color: #231f20;
  margin-bottom: 20px;
}

.Success.dark .requestid_holder h4 {
  color: #ffff;
}

.Success.dark .request_id {
  background-color: #152348;
  border: 1px solid #f7991c;
}

.request_id {
  box-shadow: 0px 12px 18px #0000000D;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  opacity: 1;
  padding: 20px 0px;
  position: relative;
  text-align: center;
  margin-bottom: 40px;
}

.request_id p {
  color: #231f20;
  font-size: 16px;
  margin: 0px;
}

.copy_holder {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
}

@media (max-width: 480px) {
  .request_id p {
    font-size: 14px;
    text-align: left;
    padding: 0px 0px 0px 10px;
  }

  .request_id svg {
    height: 20px;
    width: 20px;
  }

  .copy_holder {
    right: 10px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu {
  animation: fadeIn 0.4s ease-in-out;
  transition: all 1s;
}

.swap_label {
  text-align: left;
}

.input-holder-home {
  z-index: 99999;
  display: flex;
}

.error-text {
  color: #E50000 !important;
  font-size: 15px !important;
  margin-bottom: 10px !important;
}

@media (max-width: 760px) {
  .margin-input {
    margin-top: 20px;
  }
  .swapicon_holder {
    transform: translate(50%, -55%);

    img {

      transform: scale(1.3);
    }
  }
}

.status_modal.sender_address {
  justify-content: space-around !important;
}

.Success .sender_logoholder {
  justify-content: flex-start;
}